exports.components = {
  "component---src-components-all-articles-controller-tsx": () => import("./../../../src/components/all-articles-controller.tsx" /* webpackChunkName: "component---src-components-all-articles-controller-tsx" */),
  "component---src-components-article-controller-tsx": () => import("./../../../src/components/article-controller.tsx" /* webpackChunkName: "component---src-components-article-controller-tsx" */),
  "component---src-components-list-controller-tsx": () => import("./../../../src/components/list-controller.tsx" /* webpackChunkName: "component---src-components-list-controller-tsx" */),
  "component---src-components-page-controller-tsx": () => import("./../../../src/components/page-controller.tsx" /* webpackChunkName: "component---src-components-page-controller-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-imagine-tsx": () => import("./../../../src/pages/imagine.tsx" /* webpackChunkName: "component---src-pages-imagine-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

